import Atalanta from "./images/Atalanta.svg";
import Benevento from "./images/Benevento.svg";
import Bologna from "./images/Bologna.svg";
import Cagliari from "./images/Cagliari.svg";
import Como from "./images/Como.svg";
import Cremonese from "./images/Cremonese.svg";
import Crotone from "./images/Crotone.svg";
import Empoli from "./images/Empoli.svg";
import Fiorentina from "./images/Fiorentina.svg";
import Frosinone from "./images/Frosinone.svg";
import Genoa from "./images/Genoa.svg";
import Inter from "./images/Inter.svg";
import Juventus from "./images/Juventus.svg";
import Lazio from "./images/Lazio.svg";
import Lecce from "./images/Lecce.svg";
import Milan from "./images/Milan.svg";
import Monza from "./images/Monza.svg";
import Napoli from "./images/Napoli.svg";
import Parma from "./images/Parma.svg";
import Roma from "./images/Roma.svg";
import Salernitana from "./images/Salernitana.svg";
import Sampdoria from "./images/Sampdoria.svg";
import Sassuolo from "./images/Sassuolo.svg";
import Spezia from "./images/Spezia.svg";
import Torino from "./images/Torino.svg";
import Udinese from "./images/Udinese.svg";
import Venezia from "./images/Venezia.svg";
import Verona from "./images/Verona.svg";

const teamLogos: { [team: string]: any } = {
  Atalanta,
  Benevento,
  Bologna,
  Cagliari,
  Como,
  Cremonese,
  Crotone,
  Empoli,
  Fiorentina,
  Frosinone,
  Genoa,
  Inter,
  Juventus,
  Lazio,
  Lecce,
  Milan,
  Monza,
  Napoli,
  Parma,
  Roma,
  Salernitana,
  Sampdoria,
  Sassuolo,
  Spezia,
  Torino,
  Udinese,
  Venezia,
  Verona,
};
export default teamLogos;
