import { AuctionStage } from "@/types/liveAuction";

/**
 * `countPlayersByRole` maps players to classic roles and calculates how many players
 * for each role are present in `players`
 */
export const countPlayersByRole =
  (gameMode: GameMode) => (players: Player[], map: { [R in Role]: RoleClassic | "T" }) => {
    const sold: { [R in RoleClassic | "T"]: number } = {
      P: 0,
      D: 0,
      C: 0,
      T: 0,
      A: 0,
    };
    if (gameMode === "mantra") {
      for (const player of players) {
        sold[map[player.rolesMantra[0]]]++;
      }
    } else {
      for (const player of players) {
        sold[map[player.roleClassic]]++;
      }
    }
    return sold;
  };

export function lockRolesAtStage(stage: AuctionStage): Record<Role, boolean> {
  switch (stage) {
    case AuctionStage.Keepers:
      return {
        P: true,
        Por: true,
        D: false,
        Dd: false,
        Ds: false,
        Dc: false,
        B: false,
        C: false,
        M: false,
        E: false,
        T: false,
        W: false,
        Pc: false,
        A: false,
      };
    case AuctionStage.Defenders:
      return {
        P: true,
        Por: true,
        D: true,
        Dd: true,
        Ds: true,
        Dc: true,
        B: true,
        C: false,
        M: false,
        E: false,
        T: false,
        W: false,
        Pc: false,
        A: false,
      };
    case AuctionStage.Midfielders:
      return {
        P: true,
        Por: true,
        D: true,
        Dd: true,
        Ds: true,
        Dc: true,
        B: true,
        C: true,
        M: true,
        E: true,
        T: false,
        W: false,
        Pc: false,
        A: false,
      };
    default:
      return {
        P: true,
        Por: true,
        D: true,
        Dd: true,
        Ds: true,
        Dc: true,
        B: true,
        C: true,
        M: true,
        E: true,
        T: true,
        W: true,
        Pc: true,
        A: true,
      };
  }
}
