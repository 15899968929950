export enum AuctionStage {
  Keepers = "keepers",
  Defenders = "defenders",
  Midfielders = "midfielders",
  Forwards = "forwards",
}

export type GameMode = "classic" | "mantra";

export type Player = {
  id: number;
  name: string;
  team: string;
  roleClassic: RoleClassic;
  rolesMantra: RoleMantra[];
  amount?: number;
};

export type Team = {
  id: number;
  credits: number;
  players: Player[] | null;
  numPlayers: number;
  numKeepers: number;
  numDefenders: number;
  numMidfielders: number;
  numForwards: number;
};

export type Bid = {
  teamID: number;
  playerID: number;
  amount: number;
  isFirst?: boolean;
};

export type CurrentBid = Bid & {
  expires: string;
};

export type AuctionStateResponse = {
  liveAuctionRoomSettings: {
    leagueID: number;
    gameMode: GameMode;
    secondBidderWins: boolean;
    groupKeepersByTeam: boolean;
    enableAuctionStages: boolean;
    enableAuctionStagesMinPlayers: boolean;
    enableAuctionStagesMinPerRole: boolean;
    minKeepers: number;
    minDefenders: number;
    minMidfielders: number;
    minForwards: number;
    lenFirstTimer: number;
    lenSecondTimer: number;
  };
  id: number;
  seasonID: number;
  adminID: number;
  initialCredits: number;
  maxPlayers: number;
  enableRoleLimits: boolean;
  maxKeepers: number;
  maxDefenders: number;
  maxMidfielders: number;
  maxForwards: number;
  createdAt: string;
  updatedAt: string;
  paused: boolean;
  bidding: boolean;
  startTime: string | null;
  endTime: string | null;
  unsoldPlayerIDs: Record<number, {}>;
  teams: Record<number, Team>;
  stage: AuctionStage;
  currentBid?: CurrentBid;
};
