import { cx } from "classix";

import { PlayerRole } from "@/components/PlayerRole";
import TeamLogos from "@/components/TeamLogos";
import { isKeeper } from "@/lib/players";

const keeperClassName = "text-[#b36e00] dark:text-[#ffbe55] bg-[#f9a82533] hover:bg-[#f9a8254d]";
const defenderClassName = "text-[#2e7d32] dark:text-[#6dc572] bg-[#2e7d3233] hover:bg-[#2e7d324d]";
const midfielderClassName =
  "text-[#0277bd] dark:text-[#02a2ff] bg-[#0277bd33] hover:bg-[#0277bd4d]";
const trequartistaClassName =
  "text-[#6a1b9a] dark:text-[#c481ee] bg-[#6a1b9a33] hover:bg-[#6a1b9a4d]";
const forwardClassName = "text-[#c62828] bg-[#c6282833] hover:bg-[#c628284d] dark:text-[#fc6e6e]";

const roleToClassName: { [R in Role]: string } = {
  P: keeperClassName,
  Por: keeperClassName,
  D: defenderClassName,
  Dd: defenderClassName,
  Ds: defenderClassName,
  Dc: defenderClassName,
  B: defenderClassName,
  C: midfielderClassName,
  E: midfielderClassName,
  M: midfielderClassName,
  T: trequartistaClassName,
  W: trequartistaClassName,
  A: forwardClassName,
  Pc: forwardClassName,
};

type PlayerPillBaseProps = {
  player: Player;
  gameMode: GameMode;
  amount?: number;
  compact?: boolean;
  disabled?: boolean;
  className?: string;
  isSold?: boolean;
  groupKeepersByTeam?: boolean;
};

export function PlayerPillBase({
  player,
  amount,
  gameMode,
  compact = false,
  disabled,
  isSold,
  groupKeepersByTeam = false,
  ...props
}: PlayerPillBaseProps) {
  const roles = gameMode === "mantra" ? player.rolesMantra : [player.roleClassic];

  const name =
    groupKeepersByTeam && isKeeper(player) && !player.leftLeague ? player.team : player.name;

  return (
    <div
      className={cx(
        "flex w-full items-center justify-between rounded-md px-2",
        compact ? "h-8" : "h-10 py-2",
        gameMode === "mantra"
          ? roleToClassName[player.rolesMantra[0]]
          : roleToClassName[player.roleClassic],
        (disabled || player.leftLeague) && "!bg-neutral-200 dark:!bg-neutral-700",
        isSold && "!bg-white dark:!bg-neutral-800",
        props.className,
      )}
    >
      <div className="flex items-center justify-between">
        {player.leftLeague ? (
          <span className={compact ? "w-5" : "w-8"}>﹡</span>
        ) : (
          // eslint-disable-next-line @next/next/no-img-element
          <img
            src={TeamLogos[player.team].src}
            alt={player.team}
            width={compact ? 16 : 32}
            height={compact ? 16 : 32}
            className={cx("object-contain", compact ? "h-5 w-5" : "h-8 w-8")}
          />
        )}
        <span
          className={cx(
            "w-36 truncate text-left",
            compact ? "ml-2" : "ml-3",
            isSold && "line-through",
          )}
        >
          {name}
        </span>
        <div className="flex space-x-1">
          {roles.map(r => (
            <PlayerRole key={r} role={r} compact={compact} className="w-7 text-sm" />
          ))}
        </div>
      </div>
      {amount !== 0 && <span>{amount}</span>}
    </div>
  );
}
