import { create } from "zustand";

import { lockRolesAtStage } from "@/lib/auctionStage";
import { isDefender, isForward, isKeeper, isMidfielder } from "@/lib/players";
import type { AuctionStateResponse, Bid, CurrentBid, Player, Team } from "@/types/liveAuction";
import { AuctionStage } from "@/types/liveAuction";
import type {
  ActionPlayerNotSoldMessage,
  ActionPlayerSoldMessage,
} from "@/types/liveAuctionMessages";
import { cloneDeep } from "es-toolkit";

function immutablePlayerSoldState(team: Team, player: Player, bid: Bid): Team {
  return {
    ...team,
    credits: team.credits - bid.amount,
    players: [...(team.players ?? []), { ...player, amount: bid.amount }],
    numKeepers: isKeeper(player) ? team.numKeepers + 1 : team.numKeepers,
    numDefenders: isDefender(player) ? team.numDefenders + 1 : team.numDefenders,
    numMidfielders: isMidfielder(player) ? team.numMidfielders + 1 : team.numMidfielders,
    numForwards: isForward(player) ? team.numForwards + 1 : team.numForwards,
    numPlayers: team.numPlayers + 1,
  };
}

const roleFiltersEnabled: RoleFilters = {
  P: true,
  Por: true,
  D: true,
  Dd: true,
  Ds: true,
  Dc: true,
  C: true,
  M: true,
  E: true,
  T: true,
  W: true,
  Pc: true,
  A: true,
};

export type LiveAuctionStore = {
  id: number;
  leagueID: number;
  paused: boolean;
  startTime: string | null;
  endTime: string | null;
  setPaused: (paused: boolean) => void;
  setStart: (startTime: string, choosesNext: number) => void;
  setEndTime: (endTime: string) => void;

  gameMode: GameMode;
  randomOrder: boolean;
  setRandomPlayer: (firstBid: CurrentBid) => void;
  setRandomOrder: (value: boolean) => void;
  groupKeepersByTeam: boolean;
  enableAuctionStages: boolean;
  choosesNext: number;
  initialCredits: number;
  maxPlayers: number;
  secondBidderWins: boolean;
  minKeepers: number;
  minDefenders: number;
  minMidfielders: number;
  enableAuctionStagesMinPlayers: boolean;
  enableAuctionStagesMinPerRole: boolean;
  enableRoleLimits: boolean;
  maxKeepers: number;
  maxDefenders: number;
  maxMidfielders: number;
  maxForwards: number;
  lenFirstTimer: number;
  lenSecondTimer: number;

  firstBid?: CurrentBid;
  secondBid?: Bid;
  setBids: (firstBid: CurrentBid | undefined, secondBid: Bid | undefined) => void;
  setPlayerSold: (data: ActionPlayerSoldMessage, player: Player) => void;
  setPlayerNotSold: (data: ActionPlayerNotSoldMessage) => void;

  setInitialState: (state: AuctionStateResponse) => void;

  onlineUsers: Record<number, number | null>;
  setUserOnline: (userID: number) => void;
  setLogout: (userID: number) => void;

  teams: Record<number, Team>;
  unsoldPlayers: Record<number, {}>;
  stage: AuctionStage;

  personalRoleFilters: RoleFilters;
  globalRoleFilters: RoleFilters;
  setPersonalRoleFilters: (role: string, value: boolean) => void;

  setActionRegister: (teamID: number, userID: number) => void;
  setSkip: (choosesNext: number) => void;
};

export const useLiveAuctionStore = create<LiveAuctionStore>()(set => ({
  id: -1,
  leagueID: -1,
  paused: true,

  gameMode: "mantra",
  randomOrder: false,
  groupKeepersByTeam: false,
  enableAuctionStages: false,
  secondBidderWins: false,
  choosesNext: -1,
  startTime: null,
  endTime: null,
  initialCredits: 300,
  maxPlayers: 25,
  minKeepers: 0,
  minDefenders: 0,
  minMidfielders: 0,
  enableRoleLimits: false,
  enableAuctionStagesMinPlayers: false,
  enableAuctionStagesMinPerRole: false,
  maxKeepers: 0,
  maxDefenders: 0,
  maxMidfielders: 0,
  maxForwards: 0,
  lenFirstTimer: 5,
  lenSecondTimer: 10,

  onlineUsers: {},
  teams: {},
  unsoldPlayers: {},
  stage: AuctionStage.Keepers,

  personalRoleFilters: {
    P: false,
    Por: false,
    D: false,
    Dd: false,
    Ds: false,
    Dc: false,
    C: false,
    M: false,
    E: false,
    T: false,
    W: false,
    Pc: false,
    A: false,
  },
  globalRoleFilters: {
    P: false,
    Por: false,
    D: false,
    Dd: false,
    Ds: false,
    Dc: false,
    C: false,
    M: false,
    E: false,
    T: false,
    W: false,
    Pc: false,
    A: false,
  },
  setPersonalRoleFilters: (role, value) =>
    set(state => ({
      personalRoleFilters: {
        ...state.personalRoleFilters,
        [role]: value,
      },
    })),

  setUserOnline: userID =>
    set(state => ({
      onlineUsers: {
        ...state.onlineUsers,
        [userID]: Date.now(),
      },
    })),
  setLogout: userID =>
    set(state => ({
      onlineUsers: {
        ...state.onlineUsers,
        [userID]: null,
      },
    })),
  setInitialState: auctionState =>
    set(state => ({
      ...state,
      ...auctionState.liveAuctionRoomSettings,
      ...auctionState,
      globalRoleFilters: auctionState.liveAuctionRoomSettings.enableAuctionStages
        ? lockRolesAtStage(auctionState.stage)
        : roleFiltersEnabled,
    })),
  setRandomOrder: randomOrder => set(state => ({ ...state, randomOrder })),
  setPaused: paused => set(state => ({ ...state, paused })),
  setStart: (startTime, choosesNext) =>
    set(state => ({
      ...state,
      paused: false,
      startTime,
      choosesNext,
      globalRoleFilters: state.enableAuctionStages
        ? lockRolesAtStage(state.stage)
        : roleFiltersEnabled,
    })),
  setEndTime: endTime => set(state => ({ ...state, endTime })),

  setActionRegister: teamID =>
    set(state => ({
      teams: {
        ...state.teams,
        [teamID]: {
          id: teamID,
          players: [],
          credits: state.initialCredits,
          numPlayers: 0,
          numKeepers: 0,
          numDefenders: 0,
          numMidfielders: 0,
          numForwards: 0,
        },
      },
    })),

  setRandomPlayer: firstBid =>
    set(state => ({
      ...state,
      firstBid,
    })),
  setBids: (firstBid, secondBid) => set(state => ({ ...state, firstBid, secondBid })),
  setPlayerNotSold: data =>
    set(state => {
      const unsoldPlayers = cloneDeep(state.unsoldPlayers);
      delete unsoldPlayers[data.playerID];
      if (state.groupKeepersByTeam) {
        for (const keeperID of data.teamKeeperIDs ?? []) {
          delete unsoldPlayers[keeperID];
        }
      }
      return {
        ...state,
        unsoldPlayers,
        choosesNext: data.choosesNext,
        firstBid: undefined,
        secondBid: undefined,
      };
    }),
  setPlayerSold: (data, player) =>
    set(state => {
      const unsoldPlayers = cloneDeep(state.unsoldPlayers);
      delete unsoldPlayers[data.firstBid.playerID];
      if (state.groupKeepersByTeam) {
        for (const keeperID of data.teamKeeperIDs ?? []) {
          delete unsoldPlayers[keeperID];
        }
      }
      const firstBidTeam = state.teams[data.firstBid.teamID];
      const secondBidTeam = data.secondBid ? state.teams[data.secondBid.teamID] : undefined;
      const teams =
        state.secondBidderWins && secondBidTeam
          ? {
              ...state.teams,
              [firstBidTeam.id]: immutablePlayerSoldState(firstBidTeam, player, data.firstBid),
              [secondBidTeam.id]: immutablePlayerSoldState(secondBidTeam, player, data.firstBid),
            }
          : {
              ...state.teams,
              [firstBidTeam.id]: immutablePlayerSoldState(firstBidTeam, player, data.firstBid),
            };
      return {
        ...state,
        unsoldPlayers,
        teams,
        stage: data.stage,
        globalRoleFilters: lockRolesAtStage(data.stage),
        choosesNext: data.choosesNext,
        firstBid: undefined,
        secondBid: undefined,
      };
    }),

  setSkip: choosesNext => set(state => ({ ...state, choosesNext })),
}));
