import { cx } from "classix";

export const roleToBgColor: { [R in Role]: string } = {
  P: "bg-keeper",
  Por: "bg-keeper",
  D: "bg-defender",
  Dd: "bg-defender",
  Ds: "bg-defender",
  Dc: "bg-defender",
  B: "bg-defender",
  C: "bg-midfielder",
  E: "bg-midfielder",
  M: "bg-midfielder",
  T: "bg-winger",
  W: "bg-winger",
  A: "bg-forward",
  Pc: "bg-forward",
};

export function PlayerRole(props: { role: Role; compact?: boolean; className?: string }) {
  return (
    <span
      className={cx(
        "flex justify-center rounded font-bold text-white",
        roleToBgColor[props.role],
        props.compact ? "px-1" : "px-2 py-1",
        props.className,
      )}
    >
      {props.role}
    </span>
  );
}
