export function sortByAmount<T extends { amount?: number }>(a: T, b: T) {
  return (b.amount ?? 0) - (a.amount ?? 0);
}

export const sortByName = (a: Player | null | undefined, b: Player | null | undefined) => {
  if (a === null || a === undefined) {
    return 1;
  }
  if (b === null || b === undefined) {
    return -1;
  }
  const nameA = a.name.toUpperCase(); // ignore upper and lowercase
  const nameB = b.name.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1; //nameA comes first
  }
  if (nameA > nameB) {
    return 1; // nameB comes first
  }
  return 0; // names must be equal
};

const rosterValues: { [R in Role]: number } = {
  P: 0,
  Por: 0,
  D: 1,
  B: 1,
  Dd: 1,
  Ds: 1,
  Dc: 1,
  M: 2,
  E: 2,
  C: 2,
  T: 3,
  W: 3,
  A: 4,
  Pc: 4,
};

export const sortByRole =
  (gameMode: GameMode) =>
  <T extends { roleClassic: RoleClassic; rolesMantra: RoleMantra[] }>(a: T, b: T) => {
    if (gameMode === "classic") {
      return rosterValues[a.roleClassic] - rosterValues[b.roleClassic];
    } else {
      return rosterValues[a.rolesMantra[0]] - rosterValues[b.rolesMantra[0]];
    }
  };
