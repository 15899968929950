export function isKeeper<P extends { roleClassic: RoleClassic }>(p: P) {
  return p.roleClassic === "P";
}
export function isDefender<P extends { roleClassic: RoleClassic }>(p: P) {
  return p.roleClassic === "D";
}
export function isMidfielder<P extends { roleClassic: RoleClassic }>(p: P) {
  return p.roleClassic === "C";
}
export function isForward<P extends { roleClassic: RoleClassic }>(p: P) {
  return p.roleClassic === "A";
}
